/* login section css start */
.login-section 
  padding: rem(163px) 0 
  +media(1399px)
    padding: rem(120px) 0
  +media(991px)
    padding: rem(70px) 0
  +media(575px)
    padding: rem(50px) 0

.login-wrapper 
  @extend %bg-white 
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) 
  +border-radius(7px) 
  @extend %overflow-hidden 
  @extend %d-flex 
  .left 
    width: 50% 
    padding: rem(90px) 
    display: flex
    justify-content: center 
    align-items: center 
    +media(991px)
      display: none 
  .right 
    width: 50% 
    padding: rem(90px) rem(30px)
    +media(991px)
      width: 100% 
      padding: rem(50px) rem(30px)
  .form-control 
    background-color: #F8F8F8
/* login section css end */