/* reset css start */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap')

$heading-font: 'Roboto', sans-serif
$para-font: 'Roboto', sans-serif

html
  scroll-behavior: smooth
body
  font-family: $para-font
  color: $para-color
  font-size: rem(16px)
  padding: 0
  margin: 0 
  font-weight: 400
  position: relative
  line-height: 1.7
  background-color: $body-bg
  +transition(all 0.5s)
  overflow-x: hidden
  @extend %position-relative
  @extend %transition 
  display: flex
  flex-flow: column
  min-height: 100vh 

footer 
  margin-top: auto

+keyframes(spinRoll)
  from 
    +transform(rotate(0deg))
  to
    +transform(rotate(360deg))
img 
  max-width: 100%
  height: auto
  user-select: none
select 
  cursor: pointer
ul, ol 
  padding: 0
  margin: 0
  list-style: none
button 
  cursor: pointer
*:focus
  outline: none
button
  border: none
button:focus
  outline: none
span 
  display: inline-block
a:hover 
  color: $base-color
hr 
  background-color: $border-color 
  opacity: 0.65
/* reset css end */