/* form css start */
.form-group 
  margin-bottom: rem(15px) 
  
.form-control
  padding: rem(10px) rem(20px)
  border: none 
  width: 100%
  background-color: #F2FEFD
  font-family: $heading-font 
  height: rem(39px)
  font-weight: 300 
  +border-radius(999px)
  +placeholder(#959595)
  &:focus 
    @extend %bg-white 
    border-color: $base-color !important
    box-shadow: 0 0 5px rgba($base-color, 0.35)
    @extend %text-h 
  &[readonly] 
    background-color: rgba($base-color, 0.1)
  &.form-control-md 
    height: 45px
  &.form-control-sm
    height: 35px
textarea
  &.form-control 
    +border-radius(20px)

.select 
  padding: rem(10px) rem(15px)
  width: 100%
  border: 1px solid darken($border-color, 5%)
  cursor: pointer
  color: $para-color
  background-color: #fff
  height: rem(50px)
  +border-radius(4px)
  @extend %text-h
  option
    padding: rem(10px) 0
    display: block
    border-top: 1px solid #e5e5e5
  &.select--sm 
    height: rem(35px)
    font-size: rem(14px)
    padding: rem(5px)
textarea 
  min-height: rem(150px) !important
  resize: none
  width: 100%
label 
  color: lighten(#000, 30%)
  margin-bottom: rem(8px) 
  font-size: rem(14px)
  font-weight: 500
.input-group>.form--control, 
.input-group>.select
  position: relative
  flex: 1 1 auto
  width: 1%
  min-width: 0
.input-group 
  select 
    background-color: transparent 
    border: none

.custom--radio 
  position: relative
  padding-left: 0
  input[type=radio] 
    width: 100% 
    position: absolute
    top: 0 
    left: 0 
    visibility: hidden
    cursor: pointer
    &:checked 
      ~ label
        &::before 
          border-width: 2px
          border-color: $base-color 
        &::after 
          opacity: 1
  label 
    margin-bottom: 0 
    position: relative
    padding-left: 20px 
    font-size: rem(14px)
    font-weight: 400
    &::before 
      position: absolute
      content: '' 
      top: 4px
      left: 0 
      width: 15px 
      height: 15px 
      border: 1px solid #888888
      +border-radius(50%)
      +transition(all 0.3s)
    &::after   
      position: absolute
      content: '' 
      top: 8px
      left: 4px
      width: 7px
      height: 7px
      background-color: $base-color
      +border-radius(50%)
      opacity: 0 
      +transition(all 0.3s)

.custom--checkbox 
  padding-left: rem(25px)
  input 
    display: none
    &:checked
      ~ label 
        &::before 
          content: "\f14a"
          color: $base-color
  label 
    position: relative
    font-size: rem(15px)
    font-weight: 400
    cursor: pointer
    margin-bottom: 0
    color: #959595
    font-weight: 300 
    &::before 
      position: absolute
      content: "\f0c8"
      font-family: 'Font Awesome 5 Free'
      font-weight: 900
      top: 3px
      left: rem(-25px)
      font-size: rem(20px)
      line-height: 1
      @extend %transition 
      background-color: transparent 
      color: #ddd
/* form css end*/