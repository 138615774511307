/* feature section css start */
.feature-section 
  padding-top: rem(120px)
  padding-bottom: rem(120px)
  +media(1399px)
    padding: rem(100px) 0
  +media(991px)
    padding: rem(70px) 0
  +media(575px)
    padding: rem(50px) 0
  &.style-two 
    @extend %position-relative 
    @extend %overflow-hidden 
    .feature-thumb 
      @extend %position-absolute 
      top: 0 
      left: 0 
      width: 50% 
      @extend %h-100 
      +media(991px)
        display: none
      img 
        @extend %w-100 
        @extend %h-100 
        @extend %obj-fit 
    .feature-content 
      padding-left: rem(80px) 
      +media(1399px)
        padding-left: rem(50px) 
      +media(991px) 
        padding-left: 0 
    .feature-list 
      li 
        padding: rem(3px) rem(10px)
        @extend %bg-white 
        margin: 5px 
        width: calc(50% - 10px)
        +border-radius(80px)
        +media(1199px)
          width: 100% 

.feature-content 
  padding-left: rem(15px)
  +media(991px) 
    padding-left: 0 

.feature-title 
  max-width: 320px
  +media(991px)  
    max-width: 100% 

.feature-list 
  @extend %d-flex 
  margin: rem(-6px)
  li 
    @extend %d-flex 
    @extend %align-center 
    width: 50% 
    padding: rem(6px)
    +media(575px) 
      width: 100% 
    i 
      @extend %text-base 
      margin-right: rem(8px)
      font-size: rem(19px) 
/* feature section css end */