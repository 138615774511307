/* package section css start */
.package-section 
  padding: rem(70px) 0

.package-item 
  padding: rem(70px) rem(50px) 
  @extend %bg-white 
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) 
  +border-radius(7px) 
  +media(575px)
    padding: rem(50px) rem(30px) 
  .title 
    max-width: 300px 
    margin-left: auto 
    margin-right: auto 
  .description 
    max-width: 375px 
    margin-left: auto 
    margin-right: auto 
    +media(575px) 
      max-width: 100%
/* package section css end */