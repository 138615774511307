/* inner banner section css start */
.inner-banner-section 
  padding: rem(120px) 0
  min-height: 400px 
  display: flex
  flex-flow: column
  justify-content: center
  +media(1399px)
    padding: rem(100px) 0
    min-height: 200px
  +media(991px)
    padding: rem(70px) 0
    min-height: auto
  +media(575px)
    padding: rem(50px)
  .inner-banner-title 
    font-size: rem(45px)
    @extend %text-white 
    @media (max-width: 575px)
      font-size: rem(32px)
      
/* inner banner section css end */