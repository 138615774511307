/* book section css start */
.book-section 
  padding-top: rem(120px)
  padding-bottom: rem(120px) 
  @extend %base-color 
  +media(1399px)
    padding: rem(100px) 0
  +media(991px)
    padding: rem(70px) 0
  +media(575px)
    padding: rem(50px) 0 
  &.style-two 
    padding: rem(75px) 0 
    background: linear-gradient(77.11deg, #006761 0%, #00A69C 55.21%, #54CDC6 99.48%)
    +media(575px)
      padding: rem(50px) 0 

.book-content 
  .section-top-title 
    color: $heading-color 
  .book-title
    max-width: 320px 
  .line 
    background-color: $heading-color 

.book-form 
  padding: rem(57px) rem(22px) 
  @extend %bg-white 
  +border-radius(7px)
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05)
  +media(575px) 
    padding: rem(40px) rem(20px)


.book-wrapper 
  padding: rem(90px) rem(110px)
  @extend %bg-white 
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05)
  @extend %bs-10 
  +media(1199px)
    padding: rem(50px) rem(60px) 
  +media(991px)
    padding: rem(40px) rem(30px)
  .book-content 
    .book-title
      max-width: 100% 
  .book-form 
    padding: 0 
    box-shadow: none 
    .form-control 
      background-color: #F3F3F3 
      +border-radius(5px) 

.vertical-line 
  height: 100% 
  width: 1px
  @extend %base-color 
  margin-left: auto 
  margin-right: auto 
/* book section css end */