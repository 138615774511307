.btn 
  padding: rem(12px) rem(30px)
  +border-radius(4px)
  font-weight: 700  
  border: none 
  &:focus 
    box-shadow: none 
  &.btn-md
    padding: rem(10px) rem(20px)
  &.btn-sm 
    padding: rem(8px) rem(20px)

.btn-main 
  @extend %base-color 
  @extend %text-white
  &:hover 
    @extend %text-white 

.btn-main-outline 
  background-color: transparent 
  border: 1px solid $base-color 
  &:hover 
    @extend %base-color 
    @extend %text-white 

.btn-white 
  @extend %bg-white  
  @extend %text-base 
  color: #006761

.grad-btn 
  background: linear-gradient(90deg, #006761 0%, #00A69C 55.21%, #54CDC6 99.48%)
  @extend %text-white 
  &:hover 
    @extend %text-white 