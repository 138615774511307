/* contact section css start */
.contact-section 
  padding: rem(120px) 0 
  +media(1399px)
    padding: rem(100px) 0
  +media(991px)
    padding: rem(70px) 0
  +media(575px)
    padding: rem(50px) 0

.contact-form-area 
  padding: rem(60px) rem(50px) 
  @extend %bg-white 
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) 
  +border-radius(7px) 
  +media(575px)
    padding: rem(50px) rem(30px)
  .form-control 
    background-color: #F8F8F8 !important
/* contact section css end */