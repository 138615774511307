/* footer section css start */ 
.footer-section 
  padding: rem(45px) 0 
  @extend %base-color-two 
  &.style-two 
    padding-top: rem(65px)
    +media(575px)
      padding-top: rem(45px)


.social-links 
  @extend %d-flex 
  @extend %align-center 
  margin: rem(-3px) rem(-10px) 
  li 
    a
      padding: rem(3px) rem(10px) 
      @extend %text-base 
      font-size: rem(18px)

.inline-menu 
  @extend %d-flex 
  @extend %align-center 
  margin: rem(-5px) rem(-10px) 
  li 
    padding: rem(2px) rem(15px)
    a 
      padding: rem(3px) rem(10px)
      font-weight: 500 
      @extend %text-white 


.footer-menu 
  margin-top: rem(15px)
  li + li 
    margin-top: rem(5px) 
  li 
    a 
      @extend %text-white 
      &:hover 
        @extend %text-base

.footer-hr 
  @extend %base-color 
  margin: rem(30px) 0

.copy-right-text 
  @extend %text-white 
  font-weight: 300 
/* footer section css end */ 