/* platform feature section css start */
.platform-feature-section 
  padding: rem(120px) 0 
  +media(1399px)
    padding: rem(100px) 0
  +media(991px)
    padding: rem(70px) 0
  +media(575px)
    padding: rem(50px) 0

.mx-w-320 
  max-width: 320px 
  +media(991px) 
    max-width: 100%

.mx-w-350 
  max-width: 350px 
  +media(991px) 
    max-width: 100%

.mx-w-370 
  max-width: 370px 
  +media(991px) 
    max-width: 100%

.padding-100
  padding: rem(100px) 0
/* platform feature section css end */ 