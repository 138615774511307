/* banner section css start */
.banner-section 
  padding: rem(155px) 0
  +media(1399px)
    padding: rem(135px) 0
  +media(1199px)  
    padding: rem(100px) 0
  +media(991px) 
    padding: rem(70px) 0
  .banner-top-title 
    font-size: rem(20px)
    font-weight: 500 
  &.style-two 
    padding: rem(125px) 0
    +media(1199px)  
      padding: rem(100px) 0
    +media(991px) 
      padding: rem(70px) 0
    .banner-description  
      max-width: 465px
  &.style-three 
    @extend %position-relative 
    @extend %z-index-p 
    padding: rem(173px) 0
    +media(1399px)
      padding: rem(135px) 0
    +media(1199px)  
      padding: rem(100px) 0
    +media(991px) 
      padding: rem(70px) 0
    &::before 
      @extend %position-absolute 
      content: '' 
      top: 0 
      left: 0 
      @extend %w-100 
      @extend %h-100 
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(.jpg) 
      @extend %z-index-c
    .banner-description 
      margin-left: auto  
      margin-right: auto 

      

.banner-title 
  font-size: rem(45px) 
  +media(1199px) 
    font-size: rem(36px)
  +media(575px) 
    font-size: rem(28px)

.banner-description 
  max-width: 435px 
  font-size: rem(18px) 
  +media(991px)
    margin-left: auto 
    margin-right: auto 
    max-width: 450px 
/* banner section css end */