@import ./_reset
/* global css strat */
@import ./_color
@import ./_bg

@media (min-width: 1400px) 
  .container 
    max-width: 1230px 

.main-wrapper 
  flex-grow: 1 
  flex-shrink: 1
  flex-basis: auto 

.section-header
  margin-bottom: rem(55px)
  +media(575px)
    margin-bottom: rem(35px)

.section-title
  font-size: rem(60px)
  font-weight: 300 
  span 
    font-weight: 600
  +media(1199px)
    font-size: rem(38px)
  +media(991px)
    font-size: rem(32px)
  +media(767px)
    font-size: rem(28px)

.section-top-title 
  font-size: rem(20px)
  @extend %text-base 
  font-weight: 500 

.line 
  width: 135px
  height: 2px 
  @extend %base-color

.list-style-dot 
  &.style-two 
    li + li 
      margin-top: rem(8px) 
  li + li 
    margin-top: rem(18px)
  li 
    padding-left: rem(20px)
    @extend %position-relative 
    font-size: rem(20px)
    font-weight: 300 
    +media(1399px) 
      font-size: rem(18px) !important
    &::before 
      @extend %position-absolute 
      content: '' 
      top: 13px 
      left: 0 
      width: 9px 
      height: 9px
      @extend %base-color
      @extend %bs-50


.section-bg 
  background-color: $section-bg !important

.section-bg-two
  background-color: #006761 !important

.site-bg 
  background-color: $base-color !important

.site-color-two 
  color: #006761 !important 

.has-bg 
  background-size: cover 
  background-repeat: no-repeat

.back-to-top 
    position: fixed
    bottom: 30px 
    right: 30px
    z-index: 9 
    width: 45px 
    height: 45px
    @extend %base-color 
    box-shadow: 0 5px 10px rgba($base-color, 0.45)
    line-height: 45px
    text-align: center
    @extend %bs-50 
    display: none
    color: #fff 
    font-size: rem(24px)
    cursor: pointer

.preloader-holder
  position: fixed
  left: 0px
  top: 0px
  bottom: 0px
  right: 0px
  width: 100%
  height: 100%
  background-color: $body-bg 
  z-index: 9999

.preloader
  width: 100px
  height: 100px
  position: absolute
  left: 50%
  top: 50%
  transform: translateX(-50%) translateY(-50%)
  animation: rotatePreloader 2s infinite ease-in

  div
    position: absolute
    width: 100%
    height: 100%
    opacity: 0

    &:before
      content: ""
      position: absolute
      left: 50%
      top: 0%
      width: 10%
      height: 10%
      @extend %base-color 
      transform: translateX(-50%)
      border-radius: 50%

    &:nth-child(1)
      transform: rotateZ(0deg)
      animation: rotateCircle1 2s infinite linear
      z-index: 9

    &:nth-child(2)
      transform: rotateZ(36deg)
      animation: rotateCircle2 2s infinite linear
      z-index: 8

    &:nth-child(3)
      transform: rotateZ(72deg)
      animation: rotateCircle3 2s infinite linear
      z-index: 7

    &:nth-child(4)
      transform: rotateZ(108deg)
      animation: rotateCircle4 2s infinite linear
      z-index: 6

    &:nth-child(5)
      transform: rotateZ(144deg)
      animation: rotateCircle5 2s infinite linear
      z-index: 5

    &:nth-child(6)
      transform: rotateZ(180deg)
      animation: rotateCircle6 2s infinite linear
      z-index: 4

    &:nth-child(7)
      transform: rotateZ(216deg)
      animation: rotateCircle7 2s infinite linear
      z-index: 3

    &:nth-child(8)
      transform: rotateZ(252deg)
      animation: rotateCircle8 2s infinite linear
      z-index: 2

    &:nth-child(9)
      transform: rotateZ(288deg)
      animation: rotateCircle9 2s infinite linear
      z-index: 1

    &:nth-child(10)
      transform: rotateZ(324deg)
      animation: rotateCircle10 2s infinite linear
      z-index: 0

@keyframes rotatePreloader
  0%
    transform: translateX(-50%) translateY(-50%) rotateZ(0deg)

  100%
    transform: translateX(-50%) translateY(-50%) rotateZ(-360deg)

@keyframes rotateCircle1
  0%
    opacity: 0

  0%
    opacity: 1
    transform: rotateZ(36deg)

  7%
    transform: rotateZ(0deg)

  57%
    transform: rotateZ(0deg)

  100%
    transform: rotateZ(-324deg)
    opacity: 1

@keyframes rotateCircle2
  5%
    opacity: 0

  5.0001%
    opacity: 1
    transform: rotateZ(0deg)

  12%
    transform: rotateZ(-36deg)

  62%
    transform: rotateZ(-36deg)

  100%
    transform: rotateZ(-324deg)
    opacity: 1

@keyframes rotateCircle3
  10%
    opacity: 0

  10.0002%
    opacity: 1
    transform: rotateZ(-36deg)

  17%
    transform: rotateZ(-72deg)

  67%
    transform: rotateZ(-72deg)

  100%
    transform: rotateZ(-324deg)
    opacity: 1

@keyframes rotateCircle4
  15%
    opacity: 0

  15.0003%
    opacity: 1
    transform: rotateZ(-72deg)

  22%
    transform: rotateZ(-108deg)

  72%
    transform: rotateZ(-108deg)

  100%
    transform: rotateZ(-324deg)
    opacity: 1

@keyframes rotateCircle5
  20%
    opacity: 0

  20.0004%
    opacity: 1
    transform: rotateZ(-108deg)

  27%
    transform: rotateZ(-144deg)

  77%
    transform: rotateZ(-144deg)

  100%
    transform: rotateZ(-324deg)
    opacity: 1

@keyframes rotateCircle6
  25%
    opacity: 0

  25.0005%
    opacity: 1
    transform: rotateZ(-144deg)

  32%
    transform: rotateZ(-180deg)

  82%
    transform: rotateZ(-180deg)

  100%
    transform: rotateZ(-324deg)
    opacity: 1

@keyframes rotateCircle7
  30%
    opacity: 0

  30.0006%
    opacity: 1
    transform: rotateZ(-180deg)

  37%
    transform: rotateZ(-216deg)

  87%
    transform: rotateZ(-216deg)

  100%
    transform: rotateZ(-324deg)
    opacity: 1

@keyframes rotateCircle8
  35%
    opacity: 0

  35.0007%
    opacity: 1
    transform: rotateZ(-216deg)

  42%
    transform: rotateZ(-252deg)

  92%
    transform: rotateZ(-252deg)

  100%
    transform: rotateZ(-324deg)
    opacity: 1

@keyframes rotateCircle9
  40%
    opacity: 0

  40.0008%
    opacity: 1
    transform: rotateZ(-252deg)

  47%
    transform: rotateZ(-288deg)

  97%
    transform: rotateZ(-288deg)

  100%
    transform: rotateZ(-324deg)
    opacity: 1

@keyframes rotateCircle10
  45%
    opacity: 0

  45.0009%
    opacity: 1
    transform: rotateZ(-288deg)

  52%
    transform: rotateZ(-324deg)

  102%
    transform: rotateZ(-324deg)

  100%
    transform: rotateZ(-324deg)
    opacity: 1
/* global css end */