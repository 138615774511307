h1, .h1 
  font-size: rem(62px)
h2, .h2
  font-size: rem(45px)
  @media (max-width: 1399px)
    font-size: rem(42px)
  @media (max-width: 991px)
    font-size: rem(30px)
  @media (max-width: 575px)
    font-size: rem(28px)
h3, .h3
  font-size: rem(30px)
  +media(1199px)
    font-size: rem(26px)
  +media(767px)
    font-size: rem(24px)
  +media(575px)
    font-size: rem(22px)
h4, .h4
  font-size: rem(24px)
  @media (max-width: 767px)
    font-size: rem(20px)
h5, .h5
  font-size: rem(20px)
  @media (max-width: 767px)
    font-size: rem(18px)
h6, .h6 
  font-size: rem(16px)
h1, h2, h3, h4, h5, h6
  font-family: $heading-font
  color: $heading-color
  font-weight: 700 !important
  margin: 0
  line-height: 1.3
  word-break: break-word
h1>a, h2>a, h3>a, h4>a, h5>a, h6>a
  font-family: $heading-font
  color: $heading-color
  font-weight: 700 !important
  +transition(all 0.3s)
  line-height: 1.4
  word-break: break-word
p, li, span
  margin: 0
a 
  text-decoration: none
  display: inline-block
  font-family: $para-font
  font-weight: 400
a:hover 
  text-decoration: none

strong 
  font-weight: 500

.fs-20
  font-size: rem(20px) !important
  +media(1399px) 
    font-size: rem(18px) !important
.fs-18
  font-size: rem(18px) !important 
.fs-16
  font-size: rem(16px) !important 
.fs-15
  font-size: rem(15px) !important 
.fs-14
  font-size: rem(14px) !important  
.fs-12
  font-size: rem(12px) !important  

.h-font 
  font-family: $heading-font !important  

.p-font 
  font-family: $para-font !important  

.fw-medium 
  font-weight: 600 !important  