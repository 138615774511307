/* about section css start */
.about-section 
  padding: rem(138px) 0 
  +media(1399px)
    padding: rem(100px) 0 
  +media(991px)
    padding: rem(70px) 0 
  +media(575px)
    padding: rem(50px) 0 
  &.style-two 
    @extend %position-relative 
    @extend %overflow-hidden 
    .about-thumb 
      @extend %position-absolute 
      top: 0 
      right: 0 
      width: 50% 
      @extend %h-100 
      +media(991px)
        display: none
      img 
        @extend %w-100 
        @extend %h-100 
        @extend %obj-fit 
    .about-content 
      padding-right: rem(85px)
      +media(1199px)
        padding-right: rem(35px) 
      +media(991px) 
        padding-right: 0 
      .about-title 
        padding-right: 0 

.about-content 
  padding-right: rem(10px)
  +media(991px)
    padding-right: 0 
  .about-title 
    padding-right: rem(100px) 
    +media(1399px)
      padding-right: rem(50px)
    +media(991px)
      padding-right: 0 
/* about section css end */