/* benefit section css start */
.benefit-section 
  padding-top: rem(120px)
  padding-bottom: rem(120px) 
  +media(1399px)
    padding: rem(100px) 0
  +media(991px)
    padding: rem(70px) 0
  +media(575px)
    padding: rem(50px) 0
  &.style-two 
    @extend %position-relative 
    @extend %overflow-hidden 
    .benefit-thumb 
      @extend %position-absolute 
      top: 0 
      right: 0 
      width: 50% 
      @extend %h-100 
      +media(991px)
        display: none
      img 
        @extend %w-100 
        @extend %h-100 
        @extend %obj-fit 
    .benefit-content 
      padding-right: rem(80px)
      +media(1199px)
        padding-right: rem(40px)
      +media(991px) 
        padding-right: 0 

.benefit-content 
  padding-right: rem(50px) 
  +media(991px)
    padding-right: 0 


.benefit-item 
  padding: rem(70px) rem(100px) 
  @extend %bg-white 
  @extend %h-100 
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) 
  +border-radius(7px) 
  +media(1199px)
    padding: rem(50px) 
  +media(575px)
    padding: rem(30px) 

/* benefit section css end */